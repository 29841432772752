#pricing .view {
  background: url("http://mdbootstrap.com/img/Photos/Others/forest1.jpg")no-repeat center center;
  background-size: cover;
  height: 100vh;
}

#pricing .card {
  background-color: rgba(255, 255, 255, 0.85);;
}

#pricing .card .card-circle {
  border: 2px solid #ffffff;
}
#pricing h6 {
  line-height: 1.7;
}

#pricing .form-check {
  margin-bottom: 0!important;
}

#pricing section {
  margin-top: 10rem;
}
#pricing {
  overflow-y: scroll;
  overflow-x: auto;
}
